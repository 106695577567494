export class NavComponent {
    private elBtn: HTMLElement = document.getElementById('nav-btn') as HTMLElement;

    private elClose: HTMLElement = document.getElementById('nav-close') as HTMLElement;

    private el: HTMLElement = document.getElementById('nav') as HTMLElement;

    run(): void {
        this.initEventListener();
    }

    close(): void {
        this.onClose();
    }

    private initEventListener() {
        if (!this.el || !this.elBtn || !this.elClose) {
            return;
        }
        this.elBtn.addEventListener('click', this.onOpen.bind(this));
        this.elClose.addEventListener('click', this.onClose.bind(this));
    }

    private onOpen(): void {
        this.el?.classList.add('open');
    }

    private onClose(): void {
        this.el?.classList.remove('open');
    }
}
