const app = document.getElementById('app');
const back1 = document.getElementById('back-1');
const back2 = document.getElementById('back-2');

export const back = () => {
    if (!app) {
        return;
    }
    app.addEventListener('mousemove', (e) => {
        if (!back1 || !back2) {
            return;
        }
        const fullWidth = document.body.clientWidth;
        const diffWidthPercentage = (e.pageX * 100) / fullWidth - 50;
        const widthMaxDiff = 25;
        const diffWidth = widthMaxDiff * (diffWidthPercentage / 100);

        const fullHeight = document.body.clientWidth;
        const diffHeightPercentage = (e.pageX * 100) / fullHeight - 50;
        const heightMaxDiff = 25;
        const diffHeight = heightMaxDiff * (diffHeightPercentage / 100);

        back1.style.transform = `translateX(${diffWidth}px) translateY(${diffHeight}px)`;
        back2.style.transform = `translateX(${-diffWidth}px) translateY(${-diffHeight}px)`;
    });
};
